import React from 'react'

export default function Plug() {
  return (
    <div>
      <pre>
        <code>
          &#60;&#63;&#112;&#104;&#112;
          <br />
          &#32;&#32;&#32;&#32;&#102;&#117;&#110;&#99;&#116;&#105;&#111;&#110;&#32;&#109;&#101;&#115;&#115;&#97;&#103;&#101;&#95;&#112;&#111;&#115;&#116;&#40;&#41;&#123;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#105;&#102;&#40;&#105;&#115;&#115;&#101;&#116;&#40;&#36;&#95;&#80;&#79;&#83;&#84;&#91;&#34;&#115;&#117;&#98;&#109;&#105;&#116;&#95;&#112;&#111;&#115;&#116;&#34;&#93;&#41;&#41;&#123;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#110;&#97;&#109;&#101;&#32;&#61;&#32;&#115;&#97;&#110;&#105;&#116;&#105;&#122;&#101;&#95;&#116;&#101;&#120;&#116;&#95;&#102;&#105;&#101;&#108;&#100;&#40;&#36;&#95;&#80;&#79;&#83;&#84;&#91;&#34;&#97;&#117;&#116;&#104;&#111;&#114;&#34;&#93;&#41;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#101;&#109;&#97;&#105;&#108;&#32;&#61;&#32;&#115;&#97;&#110;&#105;&#116;&#105;&#122;&#101;&#95;&#101;&#109;&#97;&#105;&#108;&#40;&#36;&#95;&#80;&#79;&#83;&#84;&#91;&#34;&#101;&#109;&#97;&#105;&#108;&#34;&#93;&#41;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#104;&#111;&#110;&#101;&#32;&#61;&#32;&#115;&#97;&#110;&#105;&#116;&#105;&#122;&#101;&#95;&#116;&#101;&#120;&#116;&#95;&#102;&#105;&#101;&#108;&#100;&#40;&#36;&#95;&#80;&#79;&#83;&#84;&#91;&#34;&#112;&#104;&#111;&#110;&#101;&#34;&#93;&#41;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#32;&#61;&#32;&#115;&#97;&#110;&#105;&#116;&#105;&#122;&#101;&#95;&#116;&#101;&#120;&#116;&#95;&#102;&#105;&#101;&#108;&#100;&#40;&#36;&#95;&#80;&#79;&#83;&#84;&#91;&#34;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#34;&#93;&#41;&#59;
          <br />
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#32;&#61;&#32;&#97;&#114;&#114;&#97;&#121;&#40;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#39;&#112;&#111;&#115;&#116;&#95;&#116;&#105;&#116;&#108;&#101;&#39;&#32;&#61;&#62;&#32;&#36;&#110;&#97;&#109;&#101;&#44;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#39;&#112;&#111;&#115;&#116;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#39;&#32;&#61;&#62;&#32;&#36;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#44;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#39;&#112;&#111;&#115;&#116;&#95;&#115;&#116;&#97;&#116;&#117;&#115;&#39;&#32;&#61;&#62;&#32;&#39;&#112;&#117;&#98;&#108;&#105;&#115;&#104;&#39;&#44;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#39;&#112;&#111;&#115;&#116;&#95;&#97;&#117;&#116;&#104;&#111;&#114;&#39;&#32;&#61;&#62;&#32;&#49;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#41;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#105;&#100;&#32;&#61;&#32;&#119;&#112;&#95;&#105;&#110;&#115;&#101;&#114;&#116;&#95;&#112;&#111;&#115;&#116;&#40;&#36;&#112;&#111;&#115;&#116;&#41;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#117;&#112;&#100;&#97;&#116;&#101;&#95;&#112;&#111;&#115;&#116;&#95;&#109;&#101;&#116;&#97;&#40;&#36;&#112;&#111;&#115;&#116;&#95;&#105;&#100;&#44;&#32;&#39;&#101;&#109;&#97;&#105;&#108;&#39;&#44;&#32;&#36;&#101;&#109;&#97;&#105;&#108;&#41;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#117;&#112;&#100;&#97;&#116;&#101;&#95;&#112;&#111;&#115;&#116;&#95;&#109;&#101;&#116;&#97;&#40;&#36;&#112;&#111;&#115;&#116;&#95;&#105;&#100;&#44;&#32;&#39;&#112;&#104;&#111;&#110;&#101;&#39;&#44;&#32;&#36;&#112;&#104;&#111;&#110;&#101;&#41;&#59;
          <br />
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#101;&#99;&#104;&#111;&#32;&#34;&#1057;&#1086;&#1086;&#1073;&#1097;&#1077;&#1085;&#1080;&#1077;&#32;&#1086;&#1090;&#1087;&#1088;&#1072;&#1074;&#1083;&#1077;&#1085;&#1086;&#34;&#59;
          <br />
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#114;&#101;&#116;&#117;&#114;&#110;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#105;&#100;&#59;
          <br />
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;&#101;&#108;&#115;&#101;&#123;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#114;&#101;&#116;&#117;&#114;&#110;&#32;&#102;&#97;&#108;&#115;&#101;&#59;
          <br />
          &#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;
          <br />
          &#32;&#32;&#32;&#32;
          <br />
          &#32;&#32;&#32;&#32;&#125;
          <br />
          <br />
          <br />
          <br />
          &#1053;&#1072;&#1087;&#1080;&#1096;&#1080;&#1090;&#1077;&#32;&#1085;&#1072;&#1084;
          <br />
          &#60;&#102;&#111;&#114;&#109;&#32;&#109;&#101;&#116;&#104;&#111;&#100;&#61;&#34;&#112;&#111;&#115;&#116;&#34;&#32;&#97;&#99;&#116;&#105;&#111;&#110;&#61;&#34;&#34;&#62;
          <br />
          &#32;&#32;&#32;&#32;&#60;&#105;&#110;&#112;&#117;&#116;&#32;&#116;&#121;&#112;&#101;&#61;&#34;&#116;&#101;&#120;&#116;&#34;&#32;&#110;&#97;&#109;&#101;&#61;&#34;&#97;&#117;&#116;&#104;&#111;&#114;&#34;&#62;
          <br />
          &#32;&#32;&#32;&#32;&#60;&#105;&#110;&#112;&#117;&#116;&#32;&#116;&#121;&#112;&#101;&#61;&#34;&#101;&#109;&#97;&#105;&#108;&#34;&#32;&#110;&#97;&#109;&#101;&#61;&#34;&#101;&#109;&#97;&#105;&#108;&#34;&#62;
          <br />
          &#32;&#32;&#32;&#32;&#60;&#105;&#110;&#112;&#117;&#116;&#32;&#116;&#121;&#112;&#101;&#61;&#34;&#116;&#101;&#120;&#116;&#34;&#32;&#110;&#97;&#109;&#101;&#61;&#34;&#112;&#104;&#111;&#110;&#101;&#34;&#62;
          <br />
          &#32;&#32;&#32;&#32;&#60;&#105;&#110;&#112;&#117;&#116;&#32;&#116;&#121;&#112;&#101;&#61;&#34;&#116;&#101;&#120;&#116;&#34;&#32;&#110;&#97;&#109;&#101;&#61;&#34;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#34;&#62;
          <br />
          &#32;&#32;&#32;&#32;&#60;&#105;&#110;&#112;&#117;&#116;&#32;&#116;&#121;&#112;&#101;&#61;&#34;&#115;&#117;&#98;&#109;&#105;&#116;&#34;&#32;&#110;&#97;&#109;&#101;&#61;&#34;&#115;&#117;&#98;&#109;&#105;&#116;&#95;&#112;&#111;&#115;&#116;&#34;&#62;
          <br />
          &#60;&#47;&#102;&#111;&#114;&#109;&#62;
          <br />
          &#60;&#63;&#112;&#104;&#112;
          <br />
          &#101;&#99;&#104;&#111;&#32;&#100;&#111;&#95;&#115;&#104;&#111;&#114;&#116;&#99;&#111;&#100;&#101;&#40;&#39;&#91;&#99;&#101;&#110;&#115;&#111;&#114;&#93;&#39;&#41;&#59;
          <br />
          &#63;&#62;
          <br />
          <br />
          <br />
          <br />
&#60;&#63;&#112;&#104;&#112;<br/>
&#47;&#42;&#42;<br/>
&#80;&#108;&#117;&#103;&#105;&#110;&#32;&#110;&#97;&#109;&#101;&#58;&#32;&#1062;&#1077;&#1085;&#1079;&#1086;&#1088;<br/>
&#68;&#101;&#115;&#99;&#114;&#105;&#112;&#116;&#105;&#111;&#110;&#58;&#32;&#49;&#50;&#51;<br/>
&#65;&#117;&#116;&#104;&#111;&#114;&#58;&#32;&#1061;&#1072;&#1088;&#1077;&#1085;&#1082;&#1086;&#1074;&#32;&#1040;&#1085;&#1076;&#1088;&#1077;&#1081;<br/>
&#86;&#101;&#114;&#115;&#105;&#111;&#110;&#58;&#32;&#49;&#46;&#48;<br/>
<br/>
&#42;&#47;<br/>
<br/>
&#32;&#32;&#32;&#32;&#102;&#117;&#110;&#99;&#116;&#105;&#111;&#110;&#32;&#99;&#101;&#110;&#115;&#111;&#114;&#40;&#41;&#123;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#97;&#114;&#103;&#115;&#32;&#61;&#32;&#97;&#114;&#114;&#97;&#121;&#40;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#34;&#112;&#111;&#115;&#116;&#95;&#116;&#121;&#112;&#101;&#34;&#32;&#61;&#62;&#32;&#34;&#112;&#111;&#115;&#116;&#34;&#44;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#34;&#112;&#111;&#115;&#116;&#115;&#95;&#112;&#101;&#114;&#95;&#112;&#97;&#103;&#101;&#34;&#32;&#61;&#62;&#32;&#45;&#49;&#44;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#34;&#111;&#114;&#100;&#101;&#114;&#98;&#121;&#34;&#32;&#61;&#62;&#32;&#34;&#100;&#97;&#116;&#101;&#34;&#44;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#34;&#111;&#114;&#100;&#101;&#114;&#34;&#32;&#61;&#62;&#32;&#34;&#65;&#83;&#67;&#34;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#113;&#117;&#101;&#114;&#121;&#32;&#61;&#32;&#110;&#101;&#119;&#32;&#87;&#80;&#95;&#81;&#117;&#101;&#114;&#121;&#40;&#36;&#97;&#114;&#103;&#115;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#105;&#102;&#40;&#36;&#113;&#117;&#101;&#114;&#121;&#45;&#62;&#104;&#97;&#118;&#101;&#95;&#112;&#111;&#115;&#116;&#115;&#40;&#41;&#41;&#123;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#119;&#104;&#105;&#108;&#101;&#40;&#36;&#113;&#117;&#101;&#114;&#121;&#45;&#62;&#104;&#97;&#118;&#101;&#95;&#112;&#111;&#115;&#116;&#115;&#40;&#41;&#41;&#123;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#113;&#117;&#101;&#114;&#121;&#45;&#62;&#116;&#104;&#101;&#95;&#112;&#111;&#115;&#116;&#40;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#105;&#100;&#61;&#32;&#103;&#101;&#116;&#95;&#116;&#104;&#101;&#95;&#73;&#68;&#40;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#116;&#105;&#116;&#108;&#101;&#32;&#61;&#32;&#103;&#101;&#116;&#95;&#116;&#104;&#101;&#95;&#116;&#105;&#116;&#108;&#101;&#40;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#97;&#117;&#116;&#104;&#111;&#114;&#32;&#61;&#32;&#103;&#101;&#116;&#95;&#116;&#104;&#101;&#95;&#97;&#117;&#116;&#104;&#111;&#114;&#40;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#112;&#104;&#111;&#110;&#101;&#32;&#61;&#32;&#103;&#101;&#116;&#95;&#112;&#111;&#115;&#116;&#95;&#109;&#101;&#116;&#97;&#40;&#36;&#112;&#111;&#115;&#116;&#95;&#105;&#100;&#44;&#32;&#39;&#112;&#111;&#115;&#116;&#95;&#112;&#104;&#111;&#110;&#101;&#39;&#44;&#32;&#116;&#114;&#117;&#101;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#97;&#117;&#116;&#104;&#111;&#114;&#95;&#101;&#109;&#97;&#105;&#108;&#32;&#61;&#32;&#103;&#101;&#116;&#95;&#116;&#104;&#101;&#95;&#97;&#117;&#116;&#104;&#111;&#114;&#95;&#109;&#101;&#116;&#97;&#40;&#39;&#101;&#109;&#97;&#105;&#108;&#39;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#32;&#61;&#32;&#103;&#101;&#116;&#95;&#116;&#104;&#101;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#40;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#98;&#97;&#100;&#95;&#119;&#111;&#114;&#100;&#115;&#32;&#61;&#32;&#97;&#114;&#114;&#97;&#121;&#40;&#32;&#39;&#1076;&#1091;&#1088;&#1072;&#1082;&#39;&#44;&#39;&#1082;&#1086;&#1079;&#1077;&#1083;&#39;&#44;&#39;&#1082;&#1086;&#1079;&#1105;&#1083;&#39;&#44;&#39;&#1073;&#1072;&#1088;&#1072;&#1085;&#39;&#41;&#59;<br/>
<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#32;&#61;&#32;&#115;&#116;&#114;&#95;&#114;&#101;&#112;&#108;&#97;&#99;&#101;&#40;&#36;&#98;&#97;&#100;&#95;&#119;&#111;&#114;&#100;&#115;&#44;&#32;&#39;&#60;&#115;&#112;&#97;&#110;&#32;&#115;&#116;&#121;&#108;&#101;&#61;&#34;&#99;&#111;&#108;&#111;&#114;&#58;&#32;&#114;&#101;&#100;&#59;&#34;&#62;&#42;&#42;&#42;&#60;&#47;&#115;&#112;&#97;&#110;&#62;&#39;&#44;&#32;&#109;&#98;&#95;&#115;&#116;&#114;&#116;&#111;&#108;&#111;&#119;&#101;&#114;&#40;&#36;&#112;&#111;&#115;&#116;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#41;&#41;&#59;<br/>
<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#101;&#99;&#104;&#111;&#32;&#36;&#112;&#111;&#115;&#116;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#119;&#112;&#95;&#114;&#101;&#115;&#101;&#116;&#95;&#112;&#111;&#115;&#116;&#100;&#97;&#116;&#97;&#40;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;<br/>
&#32;&#32;&#32;&#32;&#125;<br/>
&#32;&#32;&#32;&#32;&#97;&#100;&#100;&#95;&#97;&#99;&#116;&#105;&#111;&#110;&#40;&#39;&#103;&#101;&#116;&#95;&#116;&#104;&#101;&#95;&#99;&#111;&#110;&#116;&#101;&#110;&#116;&#39;&#44;&#32;&#39;&#99;&#101;&#110;&#115;&#111;&#114;&#39;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;&#97;&#100;&#100;&#95;&#115;&#104;&#111;&#114;&#116;&#99;&#111;&#100;&#101;&#40;&#39;&#99;&#101;&#110;&#115;&#111;&#114;&#39;&#44;&#32;&#39;&#99;&#101;&#110;&#115;&#111;&#114;&#39;&#41;&#59;<br/>
&#32;&#32;&#32;&#32;          
           <br />
        </code>
      </pre>
    </div>
  );
}
