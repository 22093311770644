import React from 'react'
import '../styles/notfound.css'

const NotFound = () =>
  <div className="wrapper2">
  
    <div className="container2" data-text="404">
        <div className="title2 glitch2" data-text="404">
            404
        </div>
        <div className="description2 glitch2" data-text="СТРАНИЦА НЕ НАЙДЕНА">
            СТРАНИЦА НЕ НАЙДЕНА
        </div>
    </div>

</div>

export default NotFound